<template>
  <div v-show="loaded">
    <div id="learning-detail" v-if="item">
      <vx-card class="mb-4">
        <div class="flex justify-between items-center">
          <div class="card-title">
            <div class="flex items-center">
              <div class="avatar">
                <vs-avatar class="block mr-3" size="50px" :src="$storageUser + '/' + item.creator.picture" />
              </div>
              <div class="title">
                <h6>{{ moment(item.created_at).format('DD MMMM, YYYY') }} </h6>
                <h4 class="font-bold mt-1">{{ item.title }}</h4>
              </div>
            </div>
            
          </div>
          <div class="actions">
            <div class="flex items-center">
                <div class="action">
                  <vs-button color="primary" icon-pack="feather" icon="icon-edit" @click="$router.push({ name: 'learning-center-knowledge-base-edit', params: { id: $route.params.id } })" class="mr-4">
                  {{ $i18n.t('Edit') }}
                  </vs-button>
                </div>
                <vs-button color="danger" icon-pack="feather" icon="icon-trash-2" @click="confirmDeleteRecord()" v-if="isCreator">
                  {{ $i18n.t('Delete') }}
                </vs-button>                
              </div>
          </div>
        </div>
        <div class="content mt-5 ql-editor" v-html="item.content"></div>
      </vx-card>

      <!-- Attachment  -->
      <LearningTheoryAttachment :item="item"/>

    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import moment from "moment";

import LearningTheoryAttachment from '../LearningTheoryAttachment'

import 'quill/dist/quill.core.css'

export default {
  components: {
    vSelect,
    LearningTheoryAttachment
  },
  data () {
    return {
      loaded: false,
      moment: moment
    }
  },
  computed: {
    ...mapGetters({
      item: 'learningCenter/showItem',
    }),

    isCreator () {
      return this.$store.state.AppActiveUser.employee.nik == this.item.creator.nik;
    },
  },

  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, Data will be displayed.`
      })
    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    },
    getData() {
      this.id = this.$secure.decrypt(this.$route.params.id);
      this.$store.dispatch('learningCenter/detailItem', {id: this.id}).then(() => {
      this.onLoaded();
      }).catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.data.msg_status,
          color:'danger'})
      });
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("learningCenter/destroyItem", this.id)
        .then((resp) => {
          this.$vs.notify({
            color: 'success',
            title: 'Delete success',
            text: resp.data.msg_status
          })
          this.$router.push({name: 'learning-center-knowledge-base'});
        })
        .catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.msg_status,
            color:'danger'
          })
        })
    }
  },
  created () {
    this.loading();
    this.getData();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
